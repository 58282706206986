//General Imports
import React from "react";
import { graphql, Script } from "gatsby";
import { IntlProvider } from "react-intl";

//Context
import LocalesContext from "../../context/LocalesContext";

//Layout
import Layout from "../../components/Layout";
import SeoStructure from "../../components/SeoStructure";
import PostsList from "../../components/posts/PostsList";
import HeaderScripts from "../../components/HeadScripts";

export function Head({ pageContext }) {
  return (
    <>
      <HeaderScripts />
      <Script
        src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
        type="text/javascript"
      />
      <SeoStructure
        title={pageContext.postTypeName}
        description={pageContext.postTypeName}
        slug={`${pageContext.locale}/${pageContext.slug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${pageContext.postTypeName}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

const CustomPostTypeOverview = ({ data, pageContext }) => {
  let customPostData;
  if (pageContext.customPostType === "custom_post_one") {
    customPostData = data.customPostOne;
  }

  if (pageContext.customPostType === "custom_post_two") {
    customPostData = data.customPostTwo;
  }

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: `category/${pageContext.slug}`,
          avaiableLangs: [pageContext.locale],
          pageNumber: 0,
        }}
      >
        <Layout>
          <div className="custom-post-overview">
            <section className="custom-post-overview__posts">
              <PostsList
                posts={customPostData.nodes}
                limit={10}
                category={pageContext.postTypeName}
                modifier="custom-post-overview"
              />
            </section>
          </div>
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default CustomPostTypeOverview;

export const query = graphql`
  query {
    customPostOne: allWpCustomPostTypeOne(
      filter: { language: { slug: { eq: "en" } } }
    ) {
      nodes {
        id
        title
        excerpt
        slug
        featuredImage {
          node {
            title
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        language {
          slug
          name
        }
      }
    }

    customPostTwo: allWpCustomPostTypeTwo(
      filter: { language: { slug: { eq: "en" } } }
    ) {
      nodes {
        id
        title
        excerpt
        slug
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        language {
          slug
          name
        }
      }
    }
  }
`;
